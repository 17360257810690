// Card.js

import React from "react";

function OtherTour() {
  const containerStyle = {
    display: "flex",
    flexDirection: "row", // Change to "row" if you want them in a row
    alignItems: "center",
    margin: "20px",
    borderRadius: "10px",
  };

  const cardStyle = {
    border: "1px solid #ccc",
    padding: "10px",
    margin: "10px",
    borderRadius: "10px",
  };

  const titleStyle = {
    color: "grey", // Change text color to green
  };

  const titleStyle2 = {
    color: "green", // Change text color to green
  };

  const listOfObjects = [
    {
      title: "Object 1",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg", // Replace with the actual image URL
    },
    {
      title: "Object 2",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg", // Replace with the actual image URL
    },
    {
      title: "Object 3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg", // Replace with the actual image URL
    },
    {
      title: "Object 4",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg", // Replace with the actual image URL
    },
    {
      title: "Object 5",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg", // Replace with the actual image URL
    },
    {
      title: "Object 6",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg", // Replace with the actual image URL
    },
    // Add more objects as needed
  ];

  return (
    <>
      <div style={containerStyle}>
        {listOfObjects.map((data, index) => (
          <div key={index} style={cardStyle}>
            <img
              src={data.imageUrl}
              alt={`Object ${index + 1}`}
              style={{ maxWidth: "100%", borderRadius: "10px" }}
            />
            <h2>{data.title}</h2> <br />
            <h9 style={titleStyle}> {data.description}</h9>
            <br />
            <div>
              <h5 style={titleStyle2}>MORE DETAILS -&gt;</h5>
            </div>
          </div>
        ))}
      </div>
      <div style={{ backgroundColor: "#49A60A", borderRadius: "20px" }}>
        <h2 style={{ textAlign: "center", color: "white" }}>
          Other Half day Tours
        </h2>
      </div>{" "}
      <div style={containerStyle}>
        {listOfObjects.map((data, index) => (
          <div key={index} style={cardStyle}>
            <img
              src={data.imageUrl}
              alt={`Object ${index + 1}`}
              style={{ maxWidth: "100%", borderRadius: "10px" }}
            />
            <h2>{data.title}</h2> <br />
            <h9 style={titleStyle}> {data.description}</h9>
            <br />
            <div>
              <h5 style={titleStyle2}>MORE DETAILS -&gt;</h5>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default OtherTour;
