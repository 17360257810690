import "./App.css";
import Alcatraz from "./Alcatraz";
import MuirWoods from "./MuirWoods";
import SanFrancisco from "./SanFrancisco";
import Yosemite from "./Yosemite";
import Header from "./Header";
import Monterey from "./Monterey";
import OtherTour from "./OtherTour";
import Footer from "./Footer";
import Footer2 from "./Footer2";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import WineCountry from "./WineCountry";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Footer3 from "./Footer3";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Alcatraz} />
        <Route path="/alcatraz" component={Alcatraz} />
        <Route path="/muir_woods" component={MuirWoods} />
        <Route path="/san_francisco" component={SanFrancisco} />
        <Route path="/yosemite" component={Yosemite} />
        <Route path="/wine_country" component={WineCountry} />
        <Route path="/monterey" component={Monterey} />
        <Route path="/other_tour" component={OtherTour} />
      </Switch>
      <Footer3 />
      <Footer />
      <Footer2 />
    </Router>
  );
}

export default App;
