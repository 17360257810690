// Header2.js
import { useState } from "react";
import HoverAlcatraz from "./HoverAlcatraz";
import HoverMonterey from "./HoverMonterey";
import HoverSanFrancisco from "./HoverSanFrancisco";
import HoverYosemite from "./HoverYosemit"
import HoverMuirWoods from "./HoverMuirWoods";
import HoverWineCountry from "./HoverWineCountry";
import logo from "../src/images/logo.png";
import liveChat from "../src/images/live-chat.png";
import US from "../src/images/USA-flag.png";
import China from "../src/images/China-flag.png";
import Italy from "../src/images/Italy-flag.png";
import Germany from "../src/images/Germany-flag.png";
import Japan from "../src/images/Japan-flag.png";
import France from "../src/images/France-flag.png";
import Portugal from "../src/images/Portugal-flag.png";
import Russia from "../src/images/Russia-flag.png";
import Spain from "../src/images/Spain-flag.png";
import Korea from "../src/images/south-korea-flag-icon.png";


const Header2 = () => {
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const [showMuir, setShowMuir] = useState(false);
  const [showSan, setShowSan] = useState(false);
  const [showYosemite, setShowYosemite] = useState(false);
  const [showMontery, setShowMonterey] = useState(false);
  // const [showMuir, setShowMuir] = useState(false);
  const [showWine, setShowWine] = useState(false);

  const handleButtonClick = () => {
    setIsActive((prev) => !prev);
  };

  const showModal = () => {
    setShow(true);
  };
  const showModalMuir = () => {
    setShowMuir(true);
  };
  const showModalMonterey = () => {
    setShowMonterey(true);
  };
  const showModalYosemite = () => {
    setShowYosemite(true);
  };
  const showModalWine = () => {
    setShowWine(true);
  };
  const showModalSan = () => {
    setShowSan(true);
  };
  

  const hideModal = () => {
    setShow(false);
  };
  const hideModalMuir = () => {
    setShowMuir(false);
  };
  const hideModalMonterey = () => {
    setShowMonterey(false);
  };
  const hideModalYosemite = () => {
    setShowYosemite(false);
  };
  const hideModalSan = () => {
    setShowSan(false);
  };
  const hideModalWine = () => {
    setShowWine(false);
  };
 

  return (
    <header className="header-section">
      <div className="top-head">
        <div className="menu-icon">
          <a href="">
            <i className="bi bi-list"></i>
          </a>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="top-left-details text-center text-md-start py-2">
                <a href="tel:1-800 210 3008">1-800-210-3008</a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-none d-md-block">
              <div className="live-chat text-end">
                <a href="javascript:void(0);">
                  <img src={liveChat} className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="lang-icon dropdown">
          <a
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="true"
          >
            <img src={US} className="img-fluid" alt="Language" />
          </a>
          <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated">
            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={US}
                alt="USA"
              />
              USA
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={Spain}
                alt="Spain"
              />
              Spain
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={Portugal}
                alt="Portugal"
              />
              Portugal
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={France}
                alt="France"
              />
              France
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={Italy}
                alt="Italy"
              />
              Italy
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={China}
                alt="China"
              />
              China
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={Korea}
                alt="Korea"
              />
              Korea
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={Japan}
                alt="Japan"
              />
              Japan
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={Germany}
                alt="Germany"
              />
              Germany
            </a>

            <a
              href="javascript:void(0);"
              className="dropdown-item"
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              <img
                width="40"
                style={{ borderRadius: "4px" }}
                className="me-2"
                src={Russia}
                alt="Russia"
              />
              Russia
            </a>
          </div>
        </div>
      </div>
      <div className="logo-middle-head py-3 py-lg-0">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 d-none d-lg-block">
              <div className="site-logo">
                <a href="">
                  <img src={logo} className="img-fluid" />
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-8 col-lg-7">
              <div className="row ">
                <div className="col-12 col-sm-12 col-md-4 col-lg-3 d-none d-md-block align-self-center">
                  <div className={`search-form ${isActive ? "active" : ""}`}>
                    <form>
                      <input
                        type="text"
                        className={`input-control ${isActive ? "active" : ""}`}
                        placeholder="Search"
                      />
                    </form>
                    <i
                      className={`bi bi-search ${isActive ? "active" : ""}`}
                      onClick={handleButtonClick}
                    ></i>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-9">
                  <div className="site-title pb-4 pb-md-0 text-center text-xxl-start">
                    <h1>Muir Woods Tours &amp; Adventures</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-lg-3">
              <div className="d-flex gap-2 gap-lg-4 justify-content-end align-items-center mobile-search-view">
                <div className="search-form d-block d-md-none">
                  <form>
                    <input
                      type="text"
                      className="input-control"
                      placeholder="Search"
                    />
                  </form>
                  <i className="bi bi-search"></i>
                </div>
                <div className="cart-box">
                  <a href="">
                    <i className="bi bi-cart"></i>
                    <span>02</span>
                  </a>
                </div>
                <div className="book-now-box">
                  <a href="" className="book-now">
                    <i className="bi bi-calendar2-week"></i> Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navigation-section">
        <nav className="navbar navbar-expand-lg p-0">
          <div className="container-fluid">
            <a className="navbar-brand d-block d-lg-none">
              {" "}
              <img src="images/logo.png" className="img-fluid" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item active">
                  <a className="nav-link active" aria-current="page" href="#">
                    <i className="bi bi-house"></i>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onMouseEnter={showModal}
                  onMouseLeave={hideModal}
                >
                  <a className="nav-link dropdown-toggle">Alcatraz</a>
                  {show && (
        <div
          className="your-modal-class megaMenuBlock"
          onMouseEnter={showModal}
          onMouseLeave={hideModal}
        >
          <HoverAlcatraz borderRadius="10px" />
        </div>
      )}
                </li>
                <li
                  className="nav-item"
                  onMouseEnter={showModalMuir}
                  onMouseLeave={hideModalMuir}
                >
                  <a className="nav-link dropdown-toggle">Muir woods</a>
                  {showMuir && (
        <div
          className="your-modal-class megaMenuBlock"
          onMouseEnter={showModal}
          onMouseLeave={hideModal}
        >
          <HoverMuirWoods borderRadius="10px" />
        </div>
      )}
                </li>
                <li
                  className="nav-item"
                  onMouseEnter={showModalSan}
                  onMouseLeave={hideModalSan}
                >
                  <a className="nav-link dropdown-toggle">San Francisco</a>
                  {showSan && (
        <div
          className="your-modal-class megaMenuBlock"
          onMouseEnter={showModalSan}
          onMouseLeave={hideModalSan}
        >
          <HoverSanFrancisco borderRadius="10px" />
        </div>
      )}
                </li>
                <li
                  className="nav-item"
                  onMouseEnter={showModalYosemite}
                  onMouseLeave={hideModalYosemite}
                >
                  <a className="nav-link dropdown-toggle">Yosemite</a>
                  {showYosemite && (
        <div
          className="your-modal-class megaMenuBlock"
          onMouseEnter={showModalYosemite}
          onMouseLeave={hideModalYosemite}
        >
          <HoverYosemite borderRadius="10px" />
        </div>
      )}
                </li>
                <li
                  className="nav-item"
                  onMouseEnter={showModalWine}
                  onMouseLeave={hideModalWine}
                >
                  <a className="nav-link dropdown-toggle">Wine Country</a>
                  {showWine && (
        <div
          className="your-modal-class megaMenuBlock"
          onMouseEnter={showModalWine}
          onMouseLeave={hideModalWine}
        >
          <HoverWineCountry borderRadius="10px" />
        </div>
      )}
                </li>
                <li
                  className="nav-item"
                  onMouseEnter={showModalMonterey}
                  onMouseLeave={hideModalMonterey}
                >
                  <a className="nav-link dropdown-toggle">Monterey</a>
                  {showMontery && (
        <div
          className="your-modal-class megaMenuBlock"
          onMouseEnter={showModalMonterey}
          onMouseLeave={hideModalMonterey}
        >
          <HoverMonterey borderRadius="10px" />
        </div>
      )}
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Other Tour
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-animated ">
                    <li>
                      <a className="dropdown-item" href="#">
                        Point Reyes
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Horseback Ride
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Napa Balloon Ride
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Safari Adventure
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Redwood National Park
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    
    </header>
  );
};

export default Header2;
