// Header.js
import React from "react";
import Header2 from "./Header2";
import vid from "./video/video-box.mp4";
const Header = () => {
  return (
    <>
      <Header2 />
      <div className="video-section">
        <video
          autoPlay
          loop
          width="300"
          height="150"
          playsInline
          muted
          className="desktop_view"
        >
          <source src={vid} />
        </video>
      </div>
      <div className="section-equal-space bg-img">
        <div className="container-fluid">
          <div className="welcome-content text-center">
            <h2>
              Embark on an incredible journey with our Muir Woods Private Tours
              and discover epic outdoor adventures in the Bay Area and beyond,
              designed to ignite your passion for discovery and set your spirit
              free! Unleash the explorer in you and let's create unforgettable
              memories together!
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
