import React from "react";
import img from "../src/images/shape-5.png";
import footerLogo from "../src/images/Muir-Woods-Tours-sf.jpg";
import "./Styles.css";
const Footer2 = () => {
  return (
    <>
      <footer
        class="footer-section"
        style={{
          backgroundImage: `url(${img})`,
        }}
      >
        <div class="footer-navigation">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-sm-7 col-md-6 col-lg-3">
                <div class="footer-columns h-100 p-5 ps-0">
                  <div class="footer-heading">
                    <h3>Quick Links</h3>
                  </div>
                  <div class="footer-links">
                    <ul class="nav-footer">
                      <li>
                        <a href="">Home</a>
                      </li>
                      <li>
                        <a href="">Contact Us</a>
                      </li>
                      <li>
                        <a href="">About Us</a>
                      </li>
                      <li>
                        <a href="">Reviews</a>
                      </li>
                      <li>
                        <a href="">Sitemap</a>
                      </li>
                      <li>
                        <a href="">Travel Agent</a>
                      </li>
                      <li>
                        <a href="">Blog</a>
                      </li>
                      <li>
                        <a href="">FAQs</a>
                      </li>
                      <li>
                        <a href="">Careers</a>
                      </li>
                      <li>
                        <a href="">Our Guides</a>
                      </li>
                      <li>
                        <a href="">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="">Terms and Conditions</a>
                      </li>
                    </ul>
                  </div>
                  <div class="footer-btn">
                    <a href="">
                      Live Chat <i class="fa-regular fa-comments"></i>
                    </a>
                    <a href="">
                      Order Now <i class="fa-solid fa-cart-shopping"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-5 col-md-6 col-lg-3">
                <div class="footer-columns h-100 p-5 ps-0 ps-md-4">
                  <div class="footer-heading">
                    <h3>Follow Us</h3>
                  </div>
                  <div class="footer-links social-icons">
                    <ul>
                      <li class="facebook">
                        <a href="">
                          <i class="fa-brands fa-facebook-f"></i>
                          Facebook
                        </a>
                      </li>
                      <li class="twitter">
                        <a href="">
                          <i class="fa-brands fa-x-twitter"></i>
                          Twitter
                        </a>
                      </li>
                      <li class="linkedin">
                        <a href="">
                          <i class="fa-brands fa-linkedin-in"></i>
                          LinkedIn
                        </a>
                      </li>
                      <li class="youtube">
                        <a href="">
                          <i class="fa-brands fa-youtube"></i>
                          YouTube
                        </a>
                      </li>
                      <li class="instagram">
                        <a href="">
                          <i class="fa-brands fa-instagram"></i>
                          Instagram
                        </a>
                      </li>
                      <li class="pinterest">
                        <a href="">
                          <i class="fa-brands fa-pinterest-p"></i>
                          Pinterest
                        </a>
                      </li>
                      <li class="rss">
                        <a href="">
                          <i class="fa-solid fa-rss"></i>
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="login-button">
                    <a href="">Login</a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                <div class="footer-columns h-100 border-0 p-5 ps-0 ps-md-4 pe-0">
                  <div class="footer-heading">
                    <h3 class="mb-2">Why Choose Us?</h3>
                    <h4>Choose quality over quantity</h4>
                  </div>
                  <div class="goal-list">
                    <h4>our goal is to exceed your expectation</h4>
                    <ul>
                      <li>
                        <i class="bi bi-arrow-right"></i>
                        100% Local San Francisco Accredited and Established
                        Business Since 2004
                      </li>
                      <li>
                        <i class="bi bi-arrow-right"></i>
                        100% Glowing Feedback Including TripAdvisor, BBB + A
                        rating &amp; more
                      </li>
                      <li>
                        <i class="bi bi-arrow-right"></i>
                        100% Peace of Mind; Hassle-Free Booking &amp; Worry-Free
                        Tour Packages
                      </li>
                      <li>
                        <i class="bi bi-arrow-right"></i>
                        100% Trusted, Licensed and Bonded Local Tour Operator
                        Since 2004
                      </li>
                      <li>
                        <i class="bi bi-arrow-right"></i>
                        100% Tailor-Made Custom Tours &amp; Freedom to Customize
                        a La Carte
                      </li>
                      <li>
                        <i class="bi bi-arrow-right"></i>
                        Unique Itineraries &amp; Lifetime 5-Star Experiences
                        Guaranteed
                      </li>
                      <li>
                        <i class="bi bi-arrow-right"></i>
                        High Level &amp; First-Class Services - No hidden Fees
                        &amp; Satisfaction Guaranteed
                      </li>
                      <li>
                        <i class="bi bi-arrow-right"></i>
                        Superior &amp; Outstanding Customer Service, Always
                        Available &amp; Accessible
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-nav-section py-3">
          <div class="container-fluid">
            <div class="footer-nav-links">
              <ul class="list-inline text-center " >
                <li className="list-inline-item">
                  <a href="" className="hoover">Alcatraz</a>
                </li>
                <li class="list-inline-item">
                  <a href="" className="hoover">Muir Woods</a>
                </li>
                <li class="list-inline-item">
                  <a href="" className="hoover">SF </a>
                </li>
                <li class="list-inline-item">
                  <a href="" className="hoover">Yosemite</a>
                </li>
                <li class="list-inline-item">
                  <a href="" className="hoover">Lake Tahoe</a>
                </li>
                <li class="list-inline-item">
                  <a href="" className="hoover">Napa</a>
                </li>
                <li class="list-inline-item">
                  <a href="" className="hoover">Redwood National Park</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="footer-bg">
          <img src={footerLogo} class="img-fluid" />
        </div>
        <div class="copyright-section text-center p-3 bg-white">
          <p>
            Muir Woods Tours &amp; Adventures LLC © 2024. All Content is
            Protected by Copyright.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer2;
