// Alcatraz.js
import React from "react";
import img from "../src/images/tour-1.jpg";

function HoverSanFrancisco() {
  const listOfObjects = [
    {
      title: "Alcatraz & San Francisco Combo Tour",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Alcatraz & Muir Woods Tour Package",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Seaplane Adventure + Tour of Alcatraz & San Francisco",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Hosreback Ride on The Beach and Alcatraz Tour",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
  ];

  return (
    <>
      <div className="section-equal-space">
        <h4 className="megaMenuBlockTitle">
          <span className="navbar-nav me-auto mb-2 mb-lg-0">
            <b>
              Best San Francisco Tours combined with San Francisco, Napa, Silicon
              Valley & Redwoods{" "}
            </b>
          </span>
        </h4>
        <div className="yourModalWrap"> 
            <div className="row g-0">
              {listOfObjects.map((data, index) => (
                <div key={index} className="mb-2">
                  <div className="list-item d-flex align-items-center pb-2 border-bottom">
                    <div className="navitmeImg">
                      {" "}
                      <img
                        className="me-2 img-fluid"
                        src={data.imageUrl}
                        style={{ borderRadius: "5px" }}
                        alt={data.title}
                      />{" "}
                    </div>
                    <h4 className="navitmeTitle VisibleOnlyXs ">{data.title}</h4>

                    <div className="navitmeInfo">
                      <h4 className="navitmeTitle HiddenOnlyXs">{data.title}</h4>
                      <div className="navitmeBtnBlock">
                        <a href="" className="view-all-btn-hover me-3">
                          Top
                        </a>
                        <a href="" className="view-all-btn-hover me-3">
                          Popular
                        </a>
                        <a href="" className="view-all-btn-hover me-3">
                          Must See
                        </a>
                        <a href="" className="more-btn order-2 order-md-1">
                          <b>Learn More</b>{" "}
                          <i className="bi bi-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> 
        </div>
      </div>
    </>
  );
}

export default HoverSanFrancisco;
