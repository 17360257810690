// Card.js

import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { BsFillStarFill } from "react-icons/bs";
import img from "../src/images/tour-1.jpg";

function SanFrancisco() {
  const listOfObjects = [
    {
      title: "San Francisco Combo Tour",
      description:
        "Visit the ancient forest of redwoods and taste your favorite wines and premium champagne in historic Sonoma Valley, or world-famous Napa Valley. Enjoy the finest  California cuisine in quaint Sonoma Plaza, the birthplace of California, or in upscale Napa. Savor complimentary olive oil, cheeses, and chocolates, in",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
      rating: "Top 1",
    },
    {
      title: "San Francisco Combo Tour",
      description:
        "Visit the ancient forest of redwoods and taste your favorite wines and premium champagne in historic Sonoma Valley, or world-famous Napa Valley. Enjoy the finest  California cuisine in quaint Sonoma Plaza, the birthplace of California, or in upscale Napa. Savor complimentary olive oil, cheeses, and chocolates, in",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
      rating: "Top 2",
    },
    {
      title: "San Francisco Combo Tour",
      description:
        "Visit the ancient forest of redwoods and taste your favorite wines and premium champagne in historic Sonoma Valley, or world-famous Napa Valley. Enjoy the finest  California cuisine in quaint Sonoma Plaza, the birthplace of California, or in upscale Napa. Savor complimentary olive oil, cheeses, and chocolates, in",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
      rating: "Top 3",
    },
  ];

  return (
    <>
      <div className="section-equal-space">
        <div className="container-fluid">
          <div class="section-top-heading text-center mb-5">
            <h2>
              <span>San Francisco Tours &amp; Tickets </span>
            </h2>
          </div>
          <div className="row">
            {listOfObjects.map((data, index) => (
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="card-figure-box mb-4">
                  <div class="card-figure mb-4">
                    <img src={img} class="img-fluid" />
                    <span class="top-span">
                      {data.rating}
                      <span></span>
                    </span>
                  </div>
                  <div class="card-body-content mb-4">
                    <h3>{data.title}</h3>
                    <p>
                      Visit the ancient forest of redwoods and taste your
                      favorite wines and premium champagne in historic Sonoma
                      Valley, or world-famous Napa Valley. Enjoy the finest
                      California cuisine in quaint Sonoma Plaza, the birthplace
                      of California, or in upscale Napa. Savor complimentary
                      olive oil, cheeses, and chocolates, in
                    </p>
                  </div>
                  <div class="details-more d-flex flex-wrap justify-content-center justify-content-md-between align-items-center">
                    <a href="" class="more-btn order-2 order-md-1">
                      More Details <i class="bi bi-arrow-right"></i>
                    </a>
                    <div class="rating-box text-center text-md-end order-1 order-md-2 mb-3 mb-md-0">
                      <i class="bi bi-star-fill"></i>
                      <i class="bi bi-star-fill"></i>
                      <i class="bi bi-star-fill"></i>
                      <i class="bi bi-star-fill"></i>
                      <i class="bi bi-star-fill"></i>
                      <p>376 Review</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class="text-center mt-4">
            <a href="" class="view-all-btn">
              View All <i class="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="section-equal-space green-light-bg">
        <div class="container-fluid">
          <div class="section-top-heading text-center mb-5">
            <h2>
              <span>San Francisco Half-Day Tours</span>
            </h2>
          </div>
          <div class="row">
            {listOfObjects.map((data, index) => (
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="card-figure-box mb-4">
                  <div class="card-figure mb-4">
                    <img src={img} class="img-fluid" />
                    <span class="top-span">
                      {data.rating}
                      <span></span>
                    </span>
                  </div>
                  <div class="card-body-content mb-4">
                    <h3>{data.title}</h3>
                    <p>
                      Visit the ancient forest of redwoods and taste your
                      favorite wines and premium champagne in historic Sonoma
                      Valley, or world-famous Napa Valley. Enjoy the finest
                      California cuisine in quaint Sonoma Plaza, the birthplace
                      of California, or in upscale Napa. Savor complimentary
                      olive oil, cheeses, and chocolates, in
                    </p>
                  </div>
                  <div class="details-more d-flex flex-wrap justify-content-center justify-content-md-between align-items-center">
                    <a href="" class="more-btn order-2 order-md-1">
                      More Details <i class="bi bi-arrow-right"></i>
                    </a>
                    <div class="rating-box text-center text-md-end order-1 order-md-2 mb-3 mb-md-0">
                      <i class="bi bi-star-fill"> </i>
                      <i class="bi bi-star-fill"> </i>
                      <i class="bi bi-star-fill"> </i>
                      <i class="bi bi-star-fill"> </i>
                      <i class="bi bi-star-fill"> </i>
                      <p>376 Review</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div class="text-center mt-4">
            <a href="" class="view-all-btn">
              View All <i class="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default SanFrancisco;
