// Footer.js
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Styles.css";
import third from "../src/images/tour-11.jpg";

// Your React component code here

const Footer = () => {
  // const containerStyle = {
  //   display: "flex",
  //   flexDirection: "row",
  //   alignItems: "center",
  //   margin: "20px",
  // };

  const listOfObjects = [
    {
      title: "Object 1",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Object 2",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Object 3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Object 4",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Object 5",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Object 6",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
  ];

  return (
    <>
      <div className="section-equal-space">
        <div className="container-fluid">
          <div className="testimonial-title text-center mb-5">
            <h2>Client Testimonials</h2>
          </div>
          <div className="Testimonials-slide">
            <div
              id="Testimonials"
              className="owl-carousel owl-theme"
              nav
              margin={8}
            >
              {listOfObjects.map((data, index) => (
                <div className="slide-item text-center" key={index}>
                  <figure className="mb-4">
                    <img className="d-inline-block" src={third} />
                  </figure>
                  {/* <h2>{data.title}</h2> <br /> */}
                  <h3 className="mb-2">
                    <span>James Begley</span> Clearwater, FL
                  </h3>
                  <h4 className="mb-2">
                    “Exceptional Muir Woods &amp; Sausalito Tour!”
                  </h4>
                  <p>{data.description}</p>
                  <br />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
