// Footer.js
import React from "react";
//import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Styles.css";
import first from "../src/images/tour-01.jpg";
import second from "../src/images/tour-1.jpg";
import third from "../src/images/tour-11.jpg";
import fourth from "../src/images/tour-2.jpg";
import { BsArrowRight } from "react-icons/bs";

const Footer3 = () => {
  const listOfObjects = [
    {
      title: "Object 1",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Object 2",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Object 3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
    {
      title: "Object 4",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      imageUrl: "https://media.publit.io/file/butterflies.jpg",
    },
  ];

  return (
    <>
      <div className="section-equal-space">
        <div className="container-fluid">
          <div class="query-box mb-5">
            <div class="row">
              <div class="col-md-auto col-sm-12  align-self-center">
                <div class="text-center text-lg-start query-head mb-3 mb-lg-0">
                  <h2>Have a query?</h2>
                </div>
              </div>
              <div class="col-sm-12 col-md align-self-center">
                <div class="action-button text-sm-center justify-content-sm-center justify-content-lg-end row">
                 <div className="px-2 col-auto"> 
                  <a href="">Call Us Now</a>
                  </div>
                  <div className="px-2 col-auto"> 
                  <a href="" class="live-chat-btn">
                    Live Chat <i class="bi bi-arrow-right"></i>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-title text-center mb-5">
            <h2>Outdoor Adventures And Family Tours</h2>
          </div>
          <div className="Testimonials-slide">
            <div
              id="OutdoorAdventures"
              className="owl-carousel owl-theme"
              nav
              margin={8}
            >
              <div className="item">
                <div className="adventures-block text-center">
                  <figure className="mb-4">
                    <img className="d-inline-block" src={first} />
                  </figure>
                  <p>
                    The Ultimate 2-Day family getaway worry-Free tour package
                    with Alcatraz
                  </p>
                  <br />
                  <div className="action-button mt-3 d-inline-block w-100">
                    <a href="" className="view-details">
                      View Details
                    </a>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="adventures-block text-center">
                  <figure className="mb-4">
                    <img className="d-inline-block" src={second} />
                  </figure>
                  <p>
                    The Ultimate 2-Day family getaway worry-Free tour package
                    with Alcatraz
                  </p>{" "}
                  <br />
                  <div className="action-button mt-3 d-inline-block w-100">
                    <a href="" className="view-details">
                      View Details
                    </a>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="adventures-block text-center">
                  <figure className="mb-4">
                    <img className="d-inline-block" src={third} />
                  </figure>
                  <p>
                    The Ultimate 2-Day family getaway worry-Free tour package
                    with Alcatraz
                  </p>{" "}
                  <br />
                  <div className="action-button mt-3 d-inline-block w-100">
                    <a href="" className="view-details">
                      View Details
                    </a>
                  </div>
                </div>
              </div>

              

              <div className="item">
                <div className="adventures-block text-center">
                  <figure className="mb-4">
                    <img className="d-inline-block" src={fourth} />
                  </figure>
                  <p>
                    The Ultimate 2-Day family getaway worry-Free tour package
                    with Alcatraz
                  </p>{" "}
                  <br />
                  <div className="action-button mt-3 d-inline-block w-100">
                    <a href="" className="view-details">
                      View Details
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer3;
